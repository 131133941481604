import { Card, CardHeader, Divider } from '@mui/material'

const RsCard = ({ title, subheader, children, headerRightSlot, cardSx, cardProps, id }) => (
  <Card variant="outlined" sx={cardSx} id={id} {...cardProps}>
    {title && (
      <>
        <CardHeader title={title} subheader={subheader} action={headerRightSlot} />
        <Divider />
      </>
    )}

    {children}
  </Card>
)

export default RsCard
