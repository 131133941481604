import ReactDOM from 'react-dom'
import React from 'react'

export const renderComponent = (Component, elementId) => {
  const rootEl = document.getElementById(elementId);

  if (rootEl) {
    const data = JSON.parse(rootEl.getAttribute('data-props') || '{}')
    ReactDOM.render(
      <Component {...data} />,
      rootEl
    );
  }
};
