import * as Sentry from "@sentry/react";

const SentrySetup = () => {
  const isProd = import.meta.env.PROD

  let sentryDns = '';

  if(isProd){
    sentryDns = 'https://8f83ea07e48b41459c07a13e2c21de90@sentry.io/1419310'
  }

  Sentry.init({
    dsn: sentryDns,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.2,

    integrations: [Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    })],
    ignoreErrors:[
      "Non-Error exception captured",
      "Non-Error promise rejection captured"
    ]
  });
}

export default SentrySetup;
